import axios from 'axios'

import {RouteLocationNormalized} from 'vue-router'
import {iColor, iColors, iDictArrayString, iFormData, iMessageContent, iObject, iParams, iPopup, iResponseResult, iUser, iViewSettings, iViewUrlMapping, Props} from '@/utils/interfaces'
import {api_protocol, url} from '@/utils/constants'
import Reason from '@/components/popup/Reason.vue';

const setProps = async (route: RouteLocationNormalized): Promise<Props> => ({
	theme: route.params.theme as unknown as number,
	colors: route.params.colors as unknown as iColors,
	device: route.params.device as unknown as number,
	viewData: route.params.viewData as object,
	userData: route.params.userData as object as iUser,
	settings: route.params.settings as object as iViewSettings,
})

const roundBorder = (index: number, length: number, radius = '8px') : string => {
	switch (index) {
		case 0:
			return `${radius} 0 0 0`
		case 6:
			return `0 ${radius} 0 0`
		case length - 7:
			return `0 0 0 ${radius}`
		case length - 1:
			return `0 0 ${radius} 0`
		default:
			return ''
	}
}

const getDataForm = (inputs: iObject[], defaults: iObject | null = null): iFormData => {
	const obj = {
		status: true,
		error: '',
		value: Object(),
	}
	for (let i = 0; i < inputs.length; i++) {
		const input = inputs[i]
		if (!checkObject(input)) continue
		if (input.required) {
			if (input.value === '') {
				obj.error = `Compila il parametro '${input.placeholder}' per continuare`
				obj.status = false
				return obj
			}
			else if (['date'].includes(input.type) && isNaN(Date.parse(input.value))) {
				obj.error = `Il formato della data '${input.placeholder}' non è valido`
				obj.status = false
				return obj
			}
			else if (input.minLength && input.value.length < input.minLength) {
				obj.error = `Il parametro '${input.placeholder}' deve contenere almeno ${input.minLength} caratteri`
				obj.status = false
				return obj
			}
		}
		if (defaults === null || defaults[input.label] === undefined || defaults[input.label] !== input.value)
			obj.value[input.label] = input.value
	}
	return obj
}

const getDifference = (obj1: iObject, obj2: iObject): iObject => {
	const obj = Object()
	for (const key in obj1) {
		if (obj1[key] !== obj2[key])
			obj[key] = obj1[key]
	}
	return obj
}

const fill = (num: number, pad = 2, char = '0'): string => num.toString().padStart(pad, char)

const checkGeneral = (el: any): boolean => el !== undefined && el !== null
const checkString = (str: string | null | undefined): boolean => str !== '' && checkGeneral(str)
const checkArray = (arr: Array<any>): boolean => Array.isArray(arr) && arr.length > 0

const checkObject = (obj: iObject | null | undefined): boolean => obj !== null && obj !== undefined

const checkEmptyObject = (obj: iObject): boolean => checkObject(obj) && Object.keys(obj).length > 0

const checkStringArray = (arr: Array<string>): boolean => Array.isArray(arr) && arr.every(el => checkString(el))

class Color implements iColor {
	red: number
	green: number
	blue: number
	alpha: number
	constructor(color: string | Array<string>, alpha = 1) {
		if (typeof color === 'string') {
			const hex = color.replace('#', '')
			this.red = parseInt(hex.substring(0, 2), 16)
			this.green = parseInt(hex.substring(2, 4), 16)
			this.blue = parseInt(hex.substring(4, 6), 16)
		}
		else {
			this.red = parseInt(color[0])
			this.green = parseInt(color[1])
			this.blue = parseInt(color[2])
		}
		this.alpha = alpha
	}

	toRGB = () => `rgb(${this.red}, ${this.green}, ${this.blue})`
	toRGBA = (alpha: number | null | undefined) => `rgba(${this.red}, ${this.green}, ${this.blue}, ${checkGeneral(alpha) ? alpha : this.alpha})`
	toHEX = () => `#${this.red.toString(16).padStart(2, '0')}${this.green.toString(16).padStart(2, '0')}${this.blue.toString(16).padStart(2, '0')}`
	toString = () => this.toRGB()
}

const semiUrl = `${api_protocol}${url}/api`
const bookingUrl = 'booking'
const actionUrl = 'action'
const profileUrl = 'profile'

const urlMapping: iDictArrayString = {
	confirm_email: [actionUrl, 'confirm_email'].join('/'),
	join_account: [actionUrl, 'join_account'].join('/'),
	new_request_booking: [bookingUrl, 'new_request_booking'].join('/'),
}

const bookingUrls = {
	COMFIRM_BOOKING: 'confirm_booking',
	ACCEPT_BOOKING: 'accept_booking',
	NEW_BOOKING: 'new_booking',
	NEW_REQUEST_BOOKING: 'new_request_booking',
}

const bookingUrlMapping: iViewUrlMapping = {
	[bookingUrls.COMFIRM_BOOKING]: {
		accept: {
			title: 'Conferma prenotazione',
		},
		reject: {
			title: 'Rifiuta prenotazione',
		},
		onlyReject: true,
	},
	[bookingUrls.ACCEPT_BOOKING]: {
		accept: {
			title: 'Accetta prenotazione',
		},
		reject: {
			title: 'Rifiuta prenotazione',
		},
		onlyReject: true,
	},
	[bookingUrls.NEW_BOOKING]: {
		accept: {
			title: 'Crea nuova prenotazione',
		},
		reject: {
			title: 'Rifiuta creazione prenotazione',
		},
		onlyReject: true,
	},
	[bookingUrls.NEW_REQUEST_BOOKING]: {
		accept: {
			title: 'Crea prenotazione richiesta',
		},
		reject: {
			title: 'Rifiuta prenotazione richiesta',
		},
		onlyReject: true,
	},
}

const handleRequests = async (): Promise<iMessageContent> => {
	const urlParams = new URLSearchParams(window.location.search)
	const action = urlParams.get('action') || ''
	const code = urlParams.get('code') || ''
	const flag = urlParams.get('flag') || ''

	if (action === '' || code === '') {
		return {
			message: '',
			status: false,
			show: false
		}
	}

	const requestParams = {
		code: code,
		flag: flag,
	}

	const finalUrl = [semiUrl, actionUrl, action, `?${new URLSearchParams(requestParams).toString()}`].join('/')

	try {
		const response = await axios.get(finalUrl)
		const result = response.data as iResponseResult

		return {
			message: result.message,
			status: result.status,
			show: true
		}
	} catch (error) {
		console.error(error)
		return {
			message: (error as Error).toString(),
			status: false,
			show: true
		}
	}
}


const handleBookingRequests = async (reasonPopup: InstanceType<typeof Reason>): Promise<iMessageContent> => {
	const urlParams = new URLSearchParams(window.location.search)
	const action = urlParams.get('action')
	const flag = urlParams.get('flag')

	if (! (action && Object.values(bookingUrls).includes(action))) {
		return {
			message: '',
			status: false,
			show: false
		}
	}

	const code = urlParams.get('code')
	if (!code) {
		return {
			message: 'Codice non valido',
			status: false,
			show: true
		}
	}

	const urlParts = [semiUrl, bookingUrl, action]
	let title: string
	let reason = ''

	if (flag === '1') {
		title = bookingUrlMapping[action].accept.title
	} else {
		title = bookingUrlMapping[action].reject.title
	}

	if (! (bookingUrlMapping[action].onlyReject && flag === '1')) {
		const reasonResponse = await reasonPopup.open(title)
		reason = reasonResponse.reason
	}

	const requestParams = {
		code: code || '',
		reason: reason,
		flag: flag || '',
	}

	urlParts.push(`?${new URLSearchParams(requestParams).toString()}`)
	const finalUrl = urlParts.join('/')

	try {
		const response = await axios.get(finalUrl)
		const result = response.data as iResponseResult

		return {
			message: result.message,
			status: result.status,
			show: true
		}
	} catch (error) {
		console.error(error)
		let message: string
		if (error instanceof Error)
			message = error.toString()
		else
			message = String(error)
		return {
			message: message,
			status: false,
			show: true
		}
	}
}


const Popup = (key: string, title: string, permission: boolean, icon = '', data: iObject = {}, params: iParams = {action: '', params: {}}): iPopup => {
	return {
		key: key,
		title: title,
		icon: icon,
		data: data,
		show: false,
		loading: false,
		permission: permission,
		params: params,

		toString: function(): string {
			return this.title
		},
		getKey: function(): string {
			return  this.key
		},
		isShow: function(): boolean {
			return this.show
		},
		getTitle: function(): string {
			return this.title
		},
		getIcon: function(): string {
			return this.icon
		},
		getData: function(): iObject {
			return this.data
		},
		isLoading: function(): boolean {
			return this.loading
		},
		getPermission: function(): boolean {
			return this.permission
		},
		getParams: function(): iParams {
			return this.params
		},
		setTitle: function(title: string): void {
			this.title = title
		},
		setPermission: function(permission: boolean): void {
			this.permission = permission
		},
		setParams: function(params: iParams): void {
			this.params = Object.assign({}, params)
		},
		setData: function(data: iObject): void {
			this.data = Object.assign({}, data)
		},
		setLoading: function(loading: boolean): void {
			this.loading = loading
		},
		isVisible: function(): boolean {
			return this.isShow() && this.permission
		},

		open: function(loading = true): typeof this {
			this.show = true
			this.loading = loading
			return this
		},
		close: function(): void {
			this.show = false
			this.loading = true
			this.setData({})
			this.setParams({action: '', params: {}})
		},
		hide: function(): void {
			this.show = false
			this.loading = false
		}
	}
}

const PopupManager = {
    popups: [] as iPopup[],

    add(popup: iPopup): void {
        if (this.findPopup(popup.getKey()) !== null)
            return
		const lastPopup = this.getPopup()
		if (lastPopup !== null)
			lastPopup.hide()
        this.popups.push(popup)
    },
	remove(): void {
		const lastPopup = this.popups.pop()
		if (lastPopup !== undefined)
			lastPopup.close()
		if (this.isOpen())
			this.popups[this.popups.length - 1].open()
	},

	removeByKey(key: string): void {
		const index = this.popups.findIndex(popup => popup.getKey() === key)
		if (index !== -1) {
			this.popups.splice(index, 1)
			if (this.isOpen())
				this.popups[this.popups.length - 1].open()
		}
	},

    isOpen(): boolean {
        return this.popups.length > 0
    },

    getPopup(): iPopup | null {
        return this.isOpen() ? this.popups[this.popups.length - 1] : null
    },

    getPopupByKey(key: string): iPopup | null {
        const popup = this.getPopup()
        return popup !== null && popup.getKey() === key ? popup : null
    },

    findPopup(key: string): iPopup | null {
        return this.popups.find(popup => popup.getKey() === key) || null
    },

	hideLast(): void {
		const popup = this.getPopup()
		if (popup !== null)
			popup.hide()
	},

	showLast(): void {
		const popup = this.getPopup()
		if (popup !== null)
			popup.open(false)
	}
}

export {
	setProps,
	roundBorder,
	getDataForm,
	getDifference,
	fill,
	checkArray,
	checkObject,
	checkEmptyObject,
	checkStringArray,
	checkString,
	checkGeneral,
	Color,
	semiUrl,
	bookingUrl,
	actionUrl,
	profileUrl,
	handleRequests,
	handleBookingRequests,
	Popup,
	PopupManager,
	urlMapping,
	bookingUrls,
	bookingUrlMapping,
}