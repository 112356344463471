import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MessagePopup = _resolveComponent("MessagePopup")!
  const _component_ConfirmPopup = _resolveComponent("ConfirmPopup")!
  const _component_Horizontal = _resolveComponent("Horizontal")!
  const _component_Burger = _resolveComponent("Burger")!
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_Transition, { name: "message" }, {
      default: _withCtx(() => [
        (_ctx.isMessage)
          ? (_openBlock(), _createBlock(_component_MessagePopup, {
              key: 0,
              status: _ctx.message?.status,
              message: _ctx.message?.message
            }, null, 8, ["status", "message"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        _createVNode(_component_ConfirmPopup)
      ]),
      _: 1
    }),
    (_ctx.isDesktop)
      ? (_openBlock(), _createBlock(_component_Horizontal, {
          key: 0,
          currentRoute: _ctx.viewSettings.currentPage,
          pages: _ctx.pages,
          onToggleTheme: _ctx.toggleTheme
        }, null, 8, ["currentRoute", "pages", "onToggleTheme"]))
      : (_openBlock(), _createBlock(_component_Burger, {
          key: 1,
          currentRoute: _ctx.viewSettings.currentPage,
          pages: _ctx.pages,
          onToggleTheme: _ctx.toggleTheme
        }, null, 8, ["currentRoute", "pages", "onToggleTheme"])),
    _createVNode(_component_RouterView, {
      viewData: _ctx.view.data,
      userData: _ctx.account.data,
      settings: _ctx.viewSettings,
      onAction: _ctx.manageViewAction,
      onMessageContent: _ctx.addMessage
    }, null, 8, ["viewData", "userData", "settings", "onAction", "onMessageContent"])
  ], 64))
}